import dk from "@norsktest/dkdj";
import dkforms from "@norsktest/dkforms";

let handlingError = false;

export class MultipleQuantityValidator extends dkforms.validators.PositiveIntegerValidator {
    validate_value(val) {
        const v = parseInt(val);
        return (0 < v && v < 1000);
    }
}

export class SingleQuantityValidator extends dkforms.validators.PositiveIntegerValidator {
    validate_value(val) {
        const v = parseInt(val);
        return (v === 1);
    }
}

export class CartItem extends dk.Widget {
    constructor(...args) {
        const props = Object.assign(...args);
        super({
            dom_template: `
                <template>
                    <div class="shopping-cart-item">
                        <div class="item-meta" data-name="product-image"></div>
                        <div class="item-meta" data-name="product-name"></div>
                        <div data-name="quantity">
                            <input class="form-control" data-name="quantity-widget" type="number" min="1">
                        </div>
                        <a role="button" class="item-meta" data-name="item-remove">
                            <dk-icon value="minus-circle:fw" title="Fjern produkt" style="color: red; cursor: pointer; font-size: 16px"></dk-icon>
                        </a>
                        <div class="item-meta" data-name="product-price"></div>
                        <div class="item-meta" data-name="item-total"></div>
                    </div>
                </template>
            `,
        }, props);
        this.order_item = props.order_item;
        this.currency = props.currency || 'NOK';
        this.locale = props.locale || 'no-NO';
        this.can_buy_multiple_quantities = props.can_buy_multiple_quantities;
        this.max = 999;
        this.prices_with_tax = props.prices_with_tax || true;
    }

    construct() {
        this.quantity_widget.attr('max', this.can_buy_multiple_quantities ? this.max : 1);
        this.input_widget = dk.forms.IntInputWidget.create_on(this.quantity_widget);
        this.input_widget.value = this.order_item.quantity;
        if (this.can_buy_multiple_quantities) {
            new MultipleQuantityValidator(this.quantity_widget);
        } else {
            new SingleQuantityValidator(this.quantity_widget);
            this.quantity_widget
                .addClass('select-single')
                .prop('disabled', true);
        }
        this.price = this.prices_with_tax ? this.order_item.unit_price + this.order_item.product__tax : this.order_item.unit_price;
    }


    total() {
        if (this.prices_with_tax) {
            return this.gross_total();
        }
        return this.net_total();
    }

    net_total() {
        return this.order_item.unit_price * this.order_item.quantity;
    }

    gross_total() {
        return (this.order_item.unit_price + this.order_item.product__tax) * this.order_item.quantity;
    }

    tax() {
        return this.order_item.product__tax * this.order_item.quantity;
    }

    show_price_with_tax(show) {
        this.prices_with_tax = show;
        this.price = show ? this.order_item.unit_price + this.order_item.product__tax : this.order_item.unit_price;
        this.draw();
    }

    delete() {
        dkstore.login_required_json({
            url: 'delete-item/',
            data: {order_item_id: this.order_item.id},
        }, handlingError).done((response) => {
            if (response.kind === 'success') {
                this.widget().hide('slow', () => this.widget().remove());
                this.trigger('item-deleted', this);
            }
        });
    }

    update() {
        dkstore.login_required_json({
            url: 'update-item/',
            data: {
                order_item_id: this.order_item.id,
                quantity: this.order_item.quantity
            },
        }, handlingError).done((response) => {
            if (response.kind === 'success') {
                this.order_item.quantity = response.data.order_item.quantity;
                // this.input_widget.data.value = this.order_item.quantity;
                this.draw();
                this.trigger('item-updated', this);
            }
        });
    }

    valid() {
        return (0 <= this.order_item.quantity && this.order_item.quantity < 1000);
    }

    handlers() {
        this.item_remove.on('click', () => this.delete());

        dk.on(this.input_widget, 'value-changed', () => {
            this.order_item.quantity = this.input_widget.value;
            if (this.valid()) this.update();
        });

        this.quantity_widget.on('blur', () => {
            if (this.quantity_widget.val() === '') this.input_widget.value = 0;
            this.draw();
        });
    }

    draw() {
        this.product_name.html(this.order_item.product__name);
        this.product_price.html(dkstore.currency_formatter(this.price, this.currency, this.locale));
        this.item_total.html(dkstore.currency_formatter(this.total(), this.currency, this.locale));
        if (this.order_item.product__image) this.product_image.html(`<img data-name="image-url" src="${this.order_item.product__image}" alt="${this.order_item.product__name}">`);
        this.quantity_widget.trigger('prevalidate');
    }
}


export class ShoppingCart extends dk.Widget {
    constructor(...args) {
        const props = Object.assign(...args);
        const order_items = props.order_items;
        delete props.order_items;
        super({
            dom_template: `
                <template>
                    <form data-name="cart-form" autocomplete="off" name="ShoppingCart" id="ShoppingCart">
                        <div class="shopping-cart">
                            <h1>Handlekurv</h1>
                            
                            <div class="lds-roller" style="display: none;">
                                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                            </div> 
                            
                            <div class="header-buttons">
                                <div class="tax-wrapper">
                                    <div><input data-name="show-tax" type="checkbox" checked></div>
                                    <div>vis priser inkludert mva.</div>
                                </div>
                                
                                <div class="clear-cart">
                                    <button type="button" data-name="clear-btn">Tøm handlekurv</button>
                                </div>
                            </div>
                            
                            <div class="shopping-cart-header">
                                <div class="product-image"></div>
                                <div class="product-name">Produkt</div>
                                <div class="quantity">Antall</div>
                                <div class="item-remove">Slett</div>
                                <div class="product-price">Pris</div>
                                <div class="item-total">Totalt</div>
                            </div>
                            
                            <div class="shopping-cart-main">
                                <div data-name="items"></div>
                                <div data-name="empty-cart" style="display: none;">
                                    <p>Du har ingen varer i handlekurven...</p>
                                </div>
                            </div>
                            
                            <div data-name="footer">
                                <div class="sum-total">
                                    <div class="sum-text">Sum</div> <div data-name="sum">0,-</div>
                                </div>
                                <div class="tax-total">
                                    <div class="tax-text">mva.</div> <div data-name="tax">0,-</div>
                                </div>
                                <div class="sub-total">
                                    <div class="subtotal-text">Sum å betale nå</div> <div data-name="subtotal">0,-</div>
                                </div> 
                            </div>
                            <div class="action-btns">
                                <a href="../products/" class="btn btn-default" data-name="back-btn">
                                    <dk-icon value="cart-plus:fw"></dk-icon>
                                    Fortsett å handle
                                </a>
                                <button type="submit" class="btn btn-success" data-name="submit-btn">
                                    <dk-icon value="credit-card:fw"></dk-icon>
                                    Gå til kassen
                                </button>
                            </div>   
                        </div>   
                    </form>
                </template>           
            `,
        }, props);
        this.currency = props.currency || 'NOK';
        this.locale = props.locale || 'no-NO';
        this.show_product_images = props.show_product_images;
        this.can_buy_multiple_quantities = props.can_buy_multiple_quantities;
        this.order_items = order_items;
        this.cart_items = [];
        this.show_cart = (this.order_items && Array.isArray(this.order_items) && this.order_items.length > 0);

        if (!this.order_items) {
            dk.warn(`
                You did not specify any order items.
                Specify it as input when initializing the class, e.g.,

                dkstore.ShoppingCart.create_inside('#shopping-cart', {
                    order_id: {{ order_id|jsonval }},
                    order_items: {{ order_items|jsonval }}
                });

                Hint: {{ order_items }} must be a list of dkstore.OrderItem's with product_name added.
                See dkstore.dkstore.shopping_cart.
            `);
        }
    }

    // net total?
    sum_total() {
        return this.cart_items.map(widget => widget.net_total()).reduce((a, b) => a + b);
    }

    tax_total() {
        return this.cart_items.map(item => item.tax()).reduce((a, b) => a + b);
    }

    // gross total?
    sub_total() {
        return this.sum_total() + this.tax_total();
    }

    construct() {
        super.construct();
        if (this.show_cart) {
            Object.entries(this.order_items).forEach(([id, val]) => {
                this.cart_items.push(CartItem.append_to(this.items, {
                    order_item: val,
                    currency: this.currency,
                    locale: this.locale,
                    can_buy_multiple_quantities: this.can_buy_multiple_quantities,
                }));
            });
            if (!this.show_product_images) dk.$('.product-image').hide();
            // if (!this.can_buy_multiple_quantities) dk.$('.quantity-widget').prop('disabled', true);
            this.order_id = this.order_items[0].order_id;
        } else {
            this.show_empty_cart();
        }
    }

    show_empty_cart() {
        this.submit_btn.prop('disabled', true);
        this.clear_btn.prop('disabled', true);
        this.show_tax.prop('disabled', true);
        this.footer.hide();
        this.empty_cart.show();
    }

    clear() {
        dkstore.login_required_json({
            url: 'clear/',
            data: {order_id: this.order_id},
        }, handlingError).done((response) => {
            if (response.kind === 'success') {
                this.cart_items = [];
                this.items.hide('slow', () => this.items.children().remove());
                this.cart_changed();
                this.trigger('cart-cleared');
            }
        });
    }

    start_busy() {
        const widget = this.widget();
        widget.addClass('loading');
        widget.find('.btn').prop('disabled', true);
        widget.find('.lds-roller').show();
    }

    checkout() {
        this.start_busy();
        dkstore.login_required_json({
            url: '../checkout/',
            data: {order_id: this.order_id},
        }, handlingError).done((response) => {
            if (response.kind === 'success') {
                setTimeout(() =>
                    window.location.replace(`../invoice/${response.data.order.id}/`), 1500);
            }
        });
    }

    cart_changed() {
        (this.cart_items.length === 0) ? this.show_empty_cart() : this.draw();
    }

    remove_item(order_item_id) {
        this.cart_items = this.cart_items.filter(val => val.id !== order_item_id);
        this.cart_changed();
        this.trigger('item-deleted');
    }

    handlers() {
        // update cart total
        dk.on(this.cart_items, 'item-updated', () => this.draw());

        // remove item from cart
        dk.on(this.cart_items, 'item-deleted', cart_item => this.remove_item(cart_item.id));

        // clear cart
        this.clear_btn.on('click', () => this.clear());

        // submit handler
        this.cart_form.on('submit', (e) => {
            e.preventDefault();
            this.checkout();
        });

        this.show_tax.on('change', () => {
            this.cart_items.forEach((widget) => {
                widget.show_price_with_tax(this.show_tax.is(':checked'));
            });
        });
    }

    draw() {
        if (this.show_cart) {
            this.sum.html(dkstore.currency_formatter(this.sum_total(), this.currency, this.locale));
            this.tax.html(dkstore.currency_formatter(this.tax_total(), this.currency, this.locale));
            this.subtotal.html(dkstore.currency_formatter(this.sub_total(), this.currency, this.locale));
        }
    }
}
