

function invoice_status(val, rec, store_url) {
    if (val === 'paid') {
        return `<dk-icon value="check-circle:fw" style="color: green;" title="Betalt"></dk-icon> 
                <a href="/${store_url}/invoice/${rec.id}/receipt/">Kvittering</a>`;
    } else if (val === 'voided') {
        return `<dk-icon value="times-circle:fw" style="color: red;" title="Ugyldig"></dk-icon>`;
    } else if (val === 'credit') {
        return `<dk-icon value="arrow-circle-left:fw" style="color: #ffc107!important;" title="Refundert"></dk-icon>`;
    } else if (val === 'invoiced') {
        return `<dk-icon value="check-circle:fw" style="color: green;" title="Fakturert"></dk-icon>`;
    } else if (val === 'draft') {
        return `Opprettet`;
    } else if (val === 'processing') {
        return `<dk-icon value="arrow-circle-right:fw" style="color: lightgreen!important;" title="Behandles"></dk-icon>`;
    }
}


export function InvoicePanel(location, url, is_staff, store_url='store', args) {
    dk.DataTable.create_on(location, {
        table_data: dk.data.Data.create({
            pagesize: 100,
            datasource: url,
        }),
        columns: {
            order_id: {label: 'Ordrenr.'},
            invoice_date: {
                label: 'Tidspunkt',
                format: (val) => {
                    return dk.format.no_datetime(val);
                }
            },
            product: {
                label: 'Produkt(er)',
                sortable: false,
                format: function (val) {
                    let products = '';
                    val.forEach((item) => {
                        let name = item[0];
                        let quantity = item[1];
                        if (quantity > 1) {
                            products += `${name} (${quantity} stk) <br> `;
                        } else {
                            products += `${name} <br> `;
                        }
                    });
                    return products;
                }
            },
            total_amount: {
                label: 'Pris',
                format: function (val, rec) {
                    return `${dkstore.currency_formatter(val, rec.currency)}`;
                }
            },
            status: {
                label: 'Status',
                format: function (val, rec) {
                    return invoice_status(val, rec, store_url);
                }
            },
        }
    });
}
