import {ShoppingCart} from "./shopping-cart";
import {ProductList} from "./product-list";
import {currency_formatter, login_required_json} from "./utils";
import {LicensePanel} from "./license-panel";
import {InvoicePanel} from "./invoice-panel";

const dkstore = {
    ShoppingCart,
    ProductList,
    currency_formatter,
    login_required_json,
    LicensePanel,
    InvoicePanel,
};

export default dkstore;
