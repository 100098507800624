import dk from "@norsktest/dkdj";

export function currency_formatter(val, currency, locale) {
    const value = val || 0;
    const curr = currency || 'NOK';
    const loc = locale || 'no-NO';
    const formatter = new Intl.NumberFormat(loc, {
        style: 'currency',
        currency: curr
    });
    return formatter.format(value);
};


export function login_required_json(params, handlingError) {
    params = dk.$.extend({
        statusCode: {
            403: function () {
                if (handlingError) return;
                handlingError = true;
                dk.$('.ajax-error-box').html(`
                    <h3>Sesjonen har utløpt...</h3>
                    <a href="${window.location}" class="btn btn-default">Logg inn på nytt</a>
                `);

            },
        },
        error: function () {
            if (handlingError) return;
            handlingError = true;

            // disable buttons and input tags + grey out body
            dk.$('button').prop('disabled', true);
            dk.$('a').addClass('disabled');
            dk.$('input').prop('disabled', true);

            // add overlay div that covers entire page
            $('<div>', {
                class: 'ajax-error-overlay',
            }).appendTo('html');

            // add error box to be filled in specific handler (see 403 error function above).
            $('<div>', {
                class: 'ajax-error-box alert-warning',
            }).appendTo('html');

            dk.$('.ajax-error-box').html('<h3>Noe gikk galt...</h3>');
            dk.$('.ajax-error-box').append('<p>En feilmelding har blitt sendt til Norsk Test.</p>');
            dk.$('.ajax-error-box').append('<p>Vennligst prøv igjen senere.</p>');
        },
    }, params);
    return dk.json(params);
}
