function dkicon(icon, color, text) {
    return `<dk-icon value="${icon}:fw" style="color: ${color}" title="${text}"></dk-icon>${text}`;
}

function license_status(val) {
    switch (val) {
        case 'redeemed':
            return dkicon('check-circle', 'gray', 'Innløst');
            break;
        case 'expired':
            return dkicon('stop-circle', 'red', 'Utløpt');
            break;
        case 'invited':
            return dkicon('pause-circle', 'orange', 'Invitert');
            break;
        default:
            return dkicon('check-circle', 'green', 'Ledig');
    }
}


export function LicensePanel(location, args) {

    const filters = {};
    // filters.order = {
    //     label: 'Ordre',
    // };

    filters.redeemed = {
        label: 'Status',
        values: {
            all: 'Alle',
            free: 'Ledig',
            redeemed: 'Innløst',
            expired: 'Utløpt'
        }
    };

    class LicensePanelFilter extends dk.Panel {
        constructor(...args) {
            super({
                // direction: 'left',
                collapsible: false,
                title: 'Filter',
                dataset: null,
            }, ...args);
        }

        construct_panel_body(location) {
            const filterbox = dk.$('<div/>');
            location.append(filterbox);
            this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
                structure: {
                    content: {},
                },
                dataset: this.dataset,
                filters: filters
            });
            return location;
        }
    }


    class LicensePanel extends dk.ResultSet {
        constructor(...args) {
            const props = Object.assign(...args);
            const url = props.url;
            delete props.url;
            super({
                dataset: dk.data.Data.create({
                    pagesize: 10,
                    datasource: url,
                })
            }, props);
        }

        construct_filter(location, dataset) {
            return LicensePanelFilter.create_on(location, {
                dataset: dataset
            });
        }

        construct_table(location, downloadwidget, ds) {
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                table_data: ds,
                columns: {
                    status: {
                        label: 'Status',
                        format: function (val) {
                            return license_status(val);
                        }
                    },
                    key: {label: 'Lisensnøkkel'},
                    product: {label: 'Produkt'},
                    order: {label: 'Ordrenr.'},
                    user: {label: 'Bruker'},
                    activation_date: {label: 'Aktiveringsdato'},
                    expiration_date: {label: 'Utløpsdato'},
                }
            });
        }
    }

    return LicensePanel.create_on(location, args);
}

