import dk from "@norsktest/dkdj";

let handlingError = false;

export class SubmitButton extends dk.Widget {
    constructor(...args) {
        const props = Object.assign(...args);
        const current_state = props.in_cart ? 'added' : 'initial';
        super({
            dom_template: `
            <template>
                <button class="btn" data-name="add-button">
                    <dk-icon data-name="button-icon" value="plus-square:fw"></dk-icon>
                    <span data-name="button-text">Legg i handlekurv</span>
                </button>
            </template>
            `,
            states: {
                initial: {
                    icon: 'plus-square:fw',
                    text: 'Legg i handlekurv',
                    remove_class: 'btn-success',
                    add_class: 'btn-primary',
                    disabled: false
                },
                busy: {
                    icon: 'spinner:spin',
                    text: '',
                    remove_class: 'btn-success',
                    add_class: 'btn-primary',
                    disabled: true
                },
                added: {
                    icon: 'check:fw',
                    text: 'Lagt i handlekurv',
                    add_class: 'btn-success',
                    remove_class: 'btn-primary',
                    disabled: true
                }
            },
            data: {
                current_state: current_state
            }
        }, ...args);
        this.current_state = props.current_state;
        this.select_multiple = props.select_multiple;
    }

    construct() {
        this.button_icon = this.button_icon[0];  // get the dom object from the jquery object
    }

    handlers() {
        this.retrigger('click');   // convert button.click to dk click event
    }

    set state(v) {
        if (v !== this.data.current_state) this.data.current_state = v;
        this.trigger('state-changed', v);   // tell the world that something happened
        this.trigger(`state-changed-${v}`);
    }

    get state() {
        return this.data.current_state;
    }

    get state_data() {
        return this.states[this.data.current_state];
    }

    draw() {
        this.add_button.prop('disabled', this.state_data.disabled);
        this.button_icon.value = this.state_data.icon;  // <dk-icon..> er et objekt
        this.button_text.html(this.state_data.text);
        this.add_button.removeClass(this.state_data.remove_class);
        this.add_button.addClass(this.state_data.add_class);
    }
}


export class ProductItem extends dk.Widget {
    constructor(...args) {
        const props = Object.assign(...args);
        super({
            dom_template: `
            <template>
                <div class="product-list-item">
                    <div class="item" data-name="product-image"></div>
                    <div class="item" data-name="product-name"></div>
                    <div class="item" data-name="product-price"></div>
                    <div class="item" data-name="item-action"></div>
                </div>
            </template>
            `,
        }, props);
        this.product = props.product;
        this.select_multiple = props.select_multiple || null;
        this.prices_with_tax = props.prices_with_tax || true;
    }

    construct() {
        this.add_button = SubmitButton.create_inside(
            this.item_action,
            {
                select_multiple: this.select_multiple,
                in_cart: this.product.in_cart
            }
        );
        this.price = this.prices_with_tax ? this.product.price + this.product.tax : this.product.price;
    }


    handlers() {
        dk.on(this.add_button, 'click', () => this.add_to_cart());
        // dk.on(this.add_button, 'state-changed-initial', () => this.add_button.removeClass('disabled'));
    }

    start_busy() {
        this.add_button.state = 'busy';
        this.trigger('start-busy');
    }

    end_busy() {
        this.add_button.state = 'added';
        this.trigger('end-busy');
    }

    show_price_with_tax(show) {
        this.price = show ? this.product.price + this.product.tax : this.product.price;
        this.draw();
    }

    add_to_cart() {
        this.start_busy();
        dkstore.login_required_json({
            url: '../cart/add-item/',
            data: {product_id: this.product.id},
        }, handlingError).done((response) => {
            if (response.kind === 'success') {
                setTimeout(() => this.end_busy(), 100);
                this.trigger('added-to-cart', response.data.total_quantity);
            }
        });
    }

    draw() {
        this.product_name.html(this.product.name);
        this.product_price.html(dkstore.currency_formatter(this.price, this.currency, this.locale));
        if (this.product.image) this.product_image.html(`<img data-name="image-url" src="${this.product.image}" alt="${this.product.name}">`);
    }
}


export class ProductList extends dk.Widget {
    constructor(...args) {
        const props = Object.assign(...args);
        const products = props.products || null;
        delete props.products;
        super({
            dom_template: `
                <template>
                    <div class="" style="float: right; width: 80px; position: relative;">
                        <div data-name="cart-quantity">0</div>
                        <a class="cart-redirect" href="../cart/"><dk-icon value="shopping-cart:fw:4x" style="background-color: transparent"></dk-icon></a>
                    </div>
                    <h1>Produkter</h1>
                    <div class="product-list">
                        <div class="tax-wrapper">
                            <div><input data-name="show-tax" type="checkbox" checked></div>
                            <div>vis priser inkludert mva.</div>
                        </div>
                        <div class="product-list-header">
                            <div data-name="product-image" class="product-image header-item"></div>
                            <div class="product-name header-item">Navn</div>
                            <div class="product-price header-item">Pris</div>
                            <div class="item-action header-item"></div>
                        </div>
                        
                        <div class="product-list-main">
                            <div data-name="items"></div>
                            
                            <div data-name="empty" style="display: none;">
                                <p>Det er for øyeblikket ingen produkter tilgjenglig...</p>
                            </div>
                        </div>
                        <div class="action-btns">
                            <a href="../cart/" class="btn btn-success" data-name="submit-btn">
                                <dk-icon value="shopping-cart:fw" style="padding: 0!important; background-color: transparent!important"></dk-icon>
                                Gå til handlekurv
                            </a>
                        </div>
                    </div>   
                </template>           
            `,
        }, props);
        this.total_quantity = props.total_quantity || 0;
        this.currency = props.currency || 'NOK';
        this.locale = props.locale || 'no-NO';
        this.show_product_images = props.show_product_images || null;
        this.select_multiple = props.select_multiple || null;
        this.products = products;
        this.product_items = [];
        this.show_products = (this.products && Array.isArray(this.products) && this.products.length !== 0);
        if (!this.products) {
            dk.warn(`
                You did not specify any products.
                Specify it as input when initializing the class, e.g.,
    
                dkstore.ProductList.create_inside(
                    '#product-list',
                    {products: {{ products|jsonval }}}
                );
                
                Hint: {{ products }} must be a queryset (or list) of dkstore.Product's
            `);
        }
    }

    construct() {
        super.construct();
        if (this.show_products) {
            Object.entries(this.products).forEach(([idx, product]) => {
                this.product_items.push(ProductItem.append_to(this.items, {   // save the widget objects
                    product: product,
                    select_multiple: this.select_multiple,
                }));
            });
            if (!this.show_product_images) dk.$('.product-image').hide();
        } else {
            this.empty.show();
            this.submit_btn.addClass('disabled');
        }
    }

    handlers() {
        dk.on(this.product_items, 'start-busy', () => this.submit_btn.addClass('disabled'));

        dk.on(this.product_items, 'end-busy', () => this.submit_btn.removeClass('disabled'));

        // update quantity ontop of cart logo
        dk.on(this.product_items, 'added-to-cart', (val) => {
            this.total_quantity = val;
            setTimeout(() => this.draw(), 300);
        });

        // show price with/without tax
        this.show_tax.on('change', () => {
            this.product_items.forEach((widget) => {
                widget.show_price_with_tax(this.show_tax.is(':checked'));
            });
        });
    }

    draw() {
        this.cart_quantity.html(this.total_quantity);
    }
}
